<template>
  <LoadingContainer :is-loading="isLoading">
    <OrderPrint
      :order="order"
      :vat-offices="vatOffices"
      class="visible-print"
    />
    <div class="hidden-print">
      <div class="columns is-multiline">
        <div class="column is-full">
          <OrderInfo :order="order" :is-deleted="isDeleted" />
        </div>
      </div>

      <div class="columns is-multiline">
        <div class="column is-three-quarters">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">Επεξεργασία Παραγγελίας</div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <template v-if="!isDeleted" class="control">
                    <div
                      v-if="isSendMethodBoxNow && !isReferenceNumberPresent"
                      class="control"
                    >
                      <button
                        type="button"
                        class="button"
                        @click="handleBoxnowRequestClick"
                      >
                        <span>Αποστολή σε Box Now</span>
                        <span v-if="isLoadingBoxNow" class="has-text-centered">
                          <vue-loading type="spin" color="#22A684" />
                        </span>
                      </button>
                    </div>

                    <div v-if="isEditing" class="control">
                      <button
                        type="button"
                        class="button"
                        @click="isEditing = !isEditing"
                      >
                        <span class="icon is-small"><i class="fa fa-eye"/></span
                        ><span>Προβολή</span>
                      </button>
                    </div>

                    <div v-else class="control">
                      <button
                        type="button"
                        class="button"
                        @click="isEditing = !isEditing"
                      >
                        <span class="icon is-small">
                          <i class="fa fa-pencil" />
                        </span>
                        <span>Επεξεργασία</span>
                      </button>
                    </div>
                  </template>

                  <div class="control">
                    <div class="dropdown is-right is-hoverable">
                      <div class="dropdown-trigger">
                        <button
                          class="button"
                          aria-haspopup="true"
                          aria-controls="dropdown-menu"
                        >
                          <span class="icon is-small">
                            <i class="fa fa-wrench" />
                          </span>
                          <span>Επιλογές</span>
                          <span class="icon is-small">
                            <i class="fa fa-angle-down" aria-hidden="true" />
                          </span>
                        </button>
                      </div>
                      <div id="dropdown-menu" class="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                          <div class="dropdown-item" @click="resendOrder">
                            <span class="icon is-small">
                              <i
                                v-if="isSendingOrder"
                                class="fa fa-spin fa-refresh"
                              />
                              <i v-else class="fa fa-cloud-upload" />
                            </span>
                            <span>Ενημέρωση ERP</span>
                          </div>
                          <div class="dropdown-divider" />
                          <div
                            v-if="newOrder.orderable_type === 'App\\Profile'"
                            class="dropdown-item"
                            @click="copyOrder"
                          >
                            <span class="icon is-small">
                              <i class="fa fa-clone" />
                            </span>
                            <span>Αντιγραφή</span>
                          </div>
                          <div class="dropdown-item" @click="resendOrderEmail">
                            <span class="icon is-small">
                              <i
                                v-if="isSendingEmail"
                                class="fa fa-spin fa-refresh"
                              />
                              <i v-else class="fa fa-envelope-o" />
                            </span>
                            <span>Επαναποστολή email Παραγγελίας</span>
                          </div>
                          <div
                            class="dropdown-item"
                            @click="resendShippingEmail"
                          >
                            <span class="icon is-small">
                              <i
                                v-if="isSendingShipmentEmail"
                                class="fa fa-spin fa-refresh"
                              />
                              <i v-else class="fa fa-envelope-o" />
                            </span>
                            <span>
                              Επαναποστολή email Αποστολής Παραγγελίας
                            </span>
                          </div>
                          <div class="dropdown-item" @click="print">
                            <span class="icon is-small">
                              <i class="fa fa-print" />
                            </span>
                            <span>Εκτύπωση</span>
                          </div>
                          <div class="dropdown-divider" />
                          <div
                            v-if="!isDeleted"
                            class="dropdown-item"
                            @click="askToDeleteItem"
                          >
                            <span class="icon is-small">
                              <i
                                v-if="isTogglingOrder"
                                class="fa fa-spin fa-refresh"
                              />
                              <i v-else class="fa fa-trash-o" />
                            </span>
                            <span>Ακύρωση</span>
                          </div>
                          <div
                            v-else
                            class="dropdown-item"
                            @click="restoreOrder"
                          >
                            <span class="icon is-small">
                              <i
                                v-if="isTogglingOrder"
                                class="fa fa-spin fa-refresh"
                              />
                              <i v-else class="fa fa-refresh" />
                            </span>
                            <span>Επαναφορά</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div v-if="hasItems">
                <div class="table-wrapper">
                  <table class="table is-bordered is-striped is-fullwidth">
                    <thead>
                      <th>#</th>
                      <th>Φωτό</th>
                      <th>Προιόν</th>
                      <th class="table__barcode">Color/Size</th>
                      <th>Stock</th>
                      <th class="hidden-print">Εμφανές</th>
                      <th>Π.Λ. Τιμή (&euro;)</th>
                      <th>Έκπτωση (%)</th>
                      <th>Τελική Τιμή (&euro;)</th>
                      <th>Φ.Π.Α. (%)</th>
                      <th class="table__count">Ποσότητα</th>
                      <th>Σύνολο (&euro;)</th>
                      <th v-if="isEditing" class="hidden-print">Ενέργειες</th>
                    </thead>
                    <tbody>
                      <product
                        v-for="(product, index) in newOrder.products"
                        :key="index"
                        :product="product"
                        :order-cart-ids="orderCartIds"
                        :index="index"
                        :is-editing="isEditing"
                        @on-change-variation="changeVariation"
                        @on-change-count="changeCount"
                        @on-delete-item="deleteItem"
                      />
                      <product
                        v-for="(product, index) in newOrder.gifts"
                        :key="index + 'gift'"
                        :product="product"
                        :order-cart-ids="orderCartIds"
                        :index="newOrder.products.length"
                        :is-editing="isEditing"
                        :is-gift-action-gift="true"
                        @on-change-variation="changeVariation"
                        @on-change-count="changeCount"
                        @on-delete-item="deleteItem"
                      />
                    </tbody>
                  </table>
                </div>

                <ProductsSearch
                  v-if="isEditing"
                  :order="newOrder"
                  @selectProduct="selectProduct"
                />

                <Coupon
                  v-if="isEditing"
                  :has-coupon="hasCoupon"
                  :items-price="itemsTotalPrice"
                  :items="newOrder.products"
                  @change="handleCoupon"
                  @reset="resetCoupon"
                />

                <Logistics
                  :order="newOrder"
                  :has-loyalty="hasLoyalty"
                  :loyalty="loyalty"
                />
              </div>

              <div v-else class="has-text-centered">
                <i class="fa fa-shopping-basket" />
                <h4 class="title is-4">Το καλάθι είναι άδειο</h4>

                <ProductsSearch
                  v-if="isEditing"
                  :order="newOrder"
                  @selectProduct="selectProduct"
                />
              </div>
            </div>
            <div v-if="isEditing" class="card-footer">
              <div class="field is-grouped">
                <div class="control">
                  <button
                    :class="{ 'is-loading': isSaving }"
                    :disabled="isSaving"
                    type="button"
                    class="button is-primary"
                    @click="submit"
                  >
                    <span class="icon is-small"><i class="fa fa-save"/></span
                    ><span>Αποθήκευση</span>
                  </button>
                </div>
                <div class="control">
                  <button type="button" class="button" @click="reset">
                    <span class="icon is-small"><i class="fa fa-undo"/></span
                    ><span>Επαναφορά</span>
                  </button>
                </div>
              </div>
            </div>

            <Confirmation
              :model-name="order.common_id"
              :is-open="isOpen"
              title="Επιβεβαίωση Ακύρωσης"
              description="Είστε βέβαιος για την ακύρωση αυτής της παραγγελίας;"
              @closeModal="closeModal"
            />
          </div>

          <CustomerNotes
            v-if="order.customer_notes_courier"
            :notes="order.customer_notes_courier"
          />

          <CustomerNotes
            v-if="order.customer_notes"
            :notes="order.customer_notes"
          />

          <OrderAddress
            :is-deleted="isDeleted"
            :order="order"
            :vat-offices="vatOffices"
          />

          <OrderSendAddress
            :is-deleted="isDeleted"
            :order="order"
            :is-boxnow="isBoxnow"
            :is-pharmacy="isPharmacy"
            :boxnow="order.box_now"
          />

          <div class="columns">
            <div class="column is-full">
              <OrderDetails :is-deleted="isDeleted" :order="order" />
            </div>
          </div>

          <AdminNotes :order="order" />

          <Transactions :order="order" />
        </div>

        <div class="column is-one-quarter">
          <OrderStatuses
            v-if="!order.is_unfinished"
            :order="order"
            :statuses="orderStatuses"
            :is-deleted="isDeleted"
          />

          <CustomerInfo :customer="order.customer" />

          <BoxnowInfo v-if="isBoxnow" :order="order" />

          <GiftWishes :order="order" />

          <CustomerContact
            :order="order"
            :customer="order.orderable.customer"
          />

          <AdminCustomerNotes :customer="order.customer" />
        </div>
      </div>
    </div>

    <Confirmation :is-open="isOpen" @closeModal="closeModal" />
  </LoadingContainer>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { cloneDeep, round, get, pick, isEmpty, omit } from 'lodash';
import VueBarcode from 'vue-barcode';
import request from '@/utils/request';
import OrderPrint from '@/views/Dashboard/views/Orders/components/OrderPrint/';
import EditItem from '@/views/components/EditItem';
import CustomerInfo from '@/views/components/CustomerInfo';
import LoadingContainer from '@/views/components/LoadingContainer';
import AdminCustomerNotes from '@/views/components/AdminCustomerNotes';
import {
  calculateSendCharges,
  calculateProductsSum,
} from '@/utils/calculations';
import VueLoading from 'vue-loading-template';
import { MIN_COST_FOR_FREE_SHIPPING } from '@/constants/costs';
import Confirmation from './components/Confirmation';
import Logistics from '../../components/Logistics';
import Product from '../../components/Product';
import Coupon from './components/Coupon';
import AdminNotes from './components/AdminNotes';
import CustomerNotes from './components/CustomerNotes';
import CustomerContact from './components/CustomerContact';
import GiftWishes from './components/GiftWishes';
import OrderStatuses from './components/OrderStatuses';
import BoxnowInfo from './components/BoxnowInfo';
import OrderInfo from './components/OrderInfo';
import OrderAddress from './components/OrderAddress';
import OrderSendAddress from './components/OrderSendAddress';
import OrderDetails from './components/OrderDetails';
import ProductsSearch from '../../components/ProductsSearch';
import Transactions from './components/Transactions';

export default {
  components: {
    Confirmation,
    Logistics,
    Product,
    Coupon,
    AdminNotes,
    CustomerNotes,
    CustomerInfo,
    OrderStatuses,
    OrderInfo,
    OrderDetails,
    OrderAddress,
    OrderSendAddress,
    ProductsSearch,
    LoadingContainer,
    Transactions,
    CustomerContact,
    GiftWishes,
    barcode: VueBarcode,
    OrderPrint,
    AdminCustomerNotes,
    BoxnowInfo,
    VueLoading,
  },

  extends: EditItem,

  data() {
    return {
      netPrice: 0,
      totalTax: 0,
      totalPrice: 0,
      newOrder: {},
      isEditing: false,
      isSendingOrder: false,
      isSendingEmail: false,
      isSendingShipmentEmail: false,
      isTogglingOrder: false,
      vatOffices: [],
      isLoadingBoxNow: false,
    };
  },

  computed: {
    ...mapGetters({
      order: 'getOrder',
      isLoading: 'getIsLoading',
      notes: 'getNotes',
      orderStatuses: 'getOrderStatuses',
      sendCharges: 'getSendCharges',
      sendMethods: 'getSendMethods',
    }),

    hasItems() {
      return this.newOrder.products && this.newOrder.products.length > 0;
    },

    itemsTotalPrice() {
      return round(
        this.newOrder.products.reduce(
          (acc, item) => acc + item.final_price * item.pivot.count,
          0,
        ),
        2,
      );
    },

    isSendMethodBoxNow() {
      return this.order.send_method?.slug === 'box_now';
    },

    isReferenceNumberPresent() {
      return (
        !!this.order.box_now.boxnowReferenceNumber &&
        this.order.box_now.boxnowLockerId
      );
    },

    orderCartIds() {
      if (this.hasItems) {
        /* eslint-disable camelcase */
        return this.newOrder.products.map(({ cart_id }) => cart_id);
      }

      return [];
    },

    isDeleted() {
      return this.order.deleted_at !== null;
    },

    hasCoupon() {
      return !!this.newOrder.coupon;
    },

    loyalty() {
      return this.newOrder.orderable_type === 'App\\Profile'
        ? this.newOrder.orderable.customer.loyalty
        : {};
    },

    hasLoyalty() {
      return !isEmpty(this.loyalty);
    },

    isPharmacy() {
      const sendMethod = this.sendMethods.find(
        ({ id }) => id === this.newOrder.send_method_id,
      );

      return sendMethod?.slug === 'pharmacy';
    },

    isBoxnow() {
      const sendMethod = this.sendMethods.find(
        ({ id }) => id === this.newOrder.send_method_id,
      );

      return sendMethod?.slug === 'box_now';
    },
  },

  watch: {
    order: {
      handler(newVal) {
        this.newOrder = cloneDeep(newVal);
      },
      immediate: true,
      deep: true,
    },

    'newOrder.products': {
      /* eslint-disable camelcase */
      handler(newVal) {
        if (this.hasCoupon) {
          if (
            isEmpty(newVal) ||
            this.newOrder.coupon.min_price > this.itemsTotalPrice
          ) {
            this.resetCoupon();
          }
        }
      },
      deep: true,
    },
  },

  async created() {
    try {
      const response = await Promise.all([
        this.getOrder({ uuid: this.$route.params.uuid }),
        request.get('/vat-offices'),
      ]);

      this.vatOffices = response[1].data.data;
    } catch (err) {
      // this.$router.push('/error');
    }
  },

  methods: {
    ...mapMutations(['copyNewOrder', 'updateOrder']),

    ...mapActions([
      'getOrder',
      'cancelOrder',
      'activateOrder',
      'addStatus',
      'updateOrderProducts',
    ]),

    ...mapMutations(['setOrder']),

    async handleBoxnowRequestClick() {
      try {
        this.isLoadingBoxNow = true;
        const { data } = await request.put(
          `/orders/${this.$route.params.uuid}/box-now-delivery-request`,
        );

        // So the box_now info after clicking the button
        await this.$store.commit('setOrder', {
          order: {
            ...data.order,
            box_now: {
              boxnowReferenceNumber: data.box_now.boxnowReferenceNumber,
              boxnowLockerId: data.box_now.boxnowLockerId,
              boxnowParcelId: data.box_now.boxnowParcelId,
              boxnowVoucherLink: data.box_now.boxnowVoucherLink,
            },
          },
        });

        this.isLoadingBoxNow = false;
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async submit() {
      try {
        this.isSaving = true;
        await this.updateOrderProducts({
          products: this.newOrder.products.map(({ id, pivot }) => ({
            id,
            ...pick(pivot, [
              'count',
              'discount',
              'sell_price',
              'final_price',
              'code',
              'size_title',
              'color_title',
              'new_path',
              'points_multiplier',
            ]),
          })),
          coupon_code: get(this.newOrder, 'coupon.code', null),
          send_charge: this.newOrder.send_charge,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η παραγγελία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newOrder = cloneDeep(this.order);
    },

    closeModal() {
      this.isOpen = false;
    },

    async restoreOrder() {
      try {
        if (this.isTogglingOrder) {
          return;
        }

        this.isTogglingOrder = true;
        await this.activateOrder({ order: this.order });
        this.isTogglingOrder = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η παραγγελία αποκαταστάθηκε επιτυχώς',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async resendOrderEmail() {
      try {
        if (this.isSendingEmail) {
          return;
        }

        this.isSendingEmail = true;
        const response = await request.post(
          `/orders/${this.order.uuid}/resend-email`,
        );
        this.isSendingEmail = false;

        if (response.data.error) {
          throw Error('To αίτημα απέτυχε');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το email στάλθηκε επιτυχώς',
        });
      } catch (err) {
        this.isSendingEmail = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async resendShippingEmail() {
      try {
        if (this.isSendingShipmentEmail) {
          return;
        }

        this.isSendingShipmentEmail = true;
        const response = await request.post(
          `/orders/${this.order.uuid}/resend-shipping-email`,
        );
        this.isSendingShipmentEmail = false;

        if (response.data.error) {
          throw Error('To αίτημα απέτυχε');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το email στάλθηκε επιτυχώς',
        });
      } catch (err) {
        this.isSendingShipmentEmail = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async resendOrder() {
      try {
        if (this.isSendingOrder) {
          return;
        }

        this.isSendingOrder = true;
        await request.post(`/orders/${this.order.uuid}/resend`);
        this.isSendingOrder = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η παραγγελία στάλθηκε επιτυχώς',
        });
      } catch (err) {
        this.isSendingOrder = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async print() {
      try {
        await request.patch(`/orders/${this.order.uuid}/print`);

        this.updateOrder({
          order: {
            is_printed: 1,
          },
        });

        window.print();

        this.$notify({
          type: 'success',
          title: 'Επιτυχής Καταχώρηση',
          text: 'Η παραγγελία καταχωρήθηκε ως εκτυπωμένη',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία Καταχώρησης',
          text: 'To αίτημα καταχώρησης της παραγγελίας ως εκτυπωμένη απέτυχε',
        });
      }

      if (this.order.statuses.length === 0 && !this.isDeleted) {
        this.addStatus({ statusId: 1 });
      }
    },

    copyOrder() {
      this.copyNewOrder({
        order: {
          ...pick(this.newOrder, [
            'customer',
            'checkout_method_id',
            'send_method_id',
            'order_method_id',
            'products',
            'charge_address',
            'invoice',
          ]),
          orderable_id: get(this.newOrder, 'orderable_id', null),
          orderable_type: 'App\\Profile',
        },
      });

      this.$router.push({ name: 'orders.add' });
    },

    changeCount({ count, product }) {
      const products = this.newOrder.products.map(item =>
        item.cart_id === product.cart_id
          ? {
              ...item,
              pivot: {
                ...item.pivot,
                count,
              },
            }
          : item,
      );

      this.updateNewOrderProducts(products);
    },

    changeVariation({ variation, product }) {
      const newVals = omit(variation, [
        'brand',
        'code',
        'color_title',
        'id',
        'photo',
        'size_title',
      ]);

      const newPivot = pick(variation, [
        'cart_id',
        'code',
        'color_title',
        'discount',
        'final_price',
        'sell_price',
        'size_title',
      ]);

      const products = this.newOrder.products.map(item =>
        item.cart_id === product.cart_id
          ? {
              ...item,
              ...newVals,
              pivot: {
                ...item.pivot,
                ...newPivot,
                new_path: variation.photo
                  ? variation.photo.new_path
                  : item.photos[0].new_path,
              },
            }
          : item,
      );

      this.updateNewOrderProducts(products);
    },

    deleteItem(product) {
      const products = this.newOrder.products.filter(
        item => item.cart_id !== product.cart_id,
      );

      this.updateNewOrderProducts(products);
    },

    selectProduct(product) {
      const products = [
        ...this.newOrder.products,
        {
          ...product,
          pivot: {
            code: null,
            color_title: null,
            count: 1,
            discount: product.discount,
            final_price: product.final_price,
            sell_price: product.sell_price,
            size_title: null,
            new_path: product.photos[0].new_path,
            points_multiplier: product.points_multiplier,
          },
        },
      ];

      this.updateNewOrderProducts(products);
    },

    updateNewOrderProducts(products) {
      this.newOrder = {
        ...this.newOrder,
        products,
      };

      this.updateSendCharges();
    },

    handleCoupon(coupon) {
      this.newOrder = {
        ...this.newOrder,
        coupon,
      };
    },

    resetCoupon() {
      this.newOrder = {
        ...this.newOrder,
        coupon: null,
      };

      this.$notify({
        type: 'success',
        text: 'Το κουπόνι αφαιρέθηκε!!',
      });
    },

    updateSendCharges() {
      if (!isEmpty(this.newOrder.charge_address)) {
        let country;
        if (isEmpty(this.newOrder.send_address)) {
          ({ country } = this.newOrder.charge_address);
        } else {
          ({ country } = this.newOrder.send_address);
        }

        if (country) {
          const isCyprus = country === 'cy';
          const boxNowCharge = isCyprus
            ? this.sendCharges.cy.box_now.charge
            : this.sendCharges.gr.box_now.charge;

          let send_charge = 0;

          if (this.isPharmacy) {
            send_charge = 0;
          } else if (this.isBoxnow) {
            const { sum } = calculateProductsSum(this.newOrder.products);
            send_charge = sum >= MIN_COST_FOR_FREE_SHIPPING ? 0 : boxNowCharge;
          } else {
            const { sum, weight } = calculateProductsSum(
              this.newOrder.products,
            );
            send_charge = calculateSendCharges(
              sum,
              weight,
              isCyprus ? this.sendCharges.cy : this.sendCharges.gr,
            );
          }

          this.newOrder = {
            ...this.newOrder,
            send_charge,
          };
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-x: auto;
}

.table {
  margin: -1px 0 0;

  &__barcode {
    min-width: 170px;
  }

  &__count {
    max-width: 100px;
  }
}

.fa {
  &.fa-shopping-basket {
    font-size: 40px;
    margin-bottom: 1rem;
  }
}
</style>
