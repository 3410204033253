<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Στοιχεία Χρέωσης
          </div>
        </div>
        <div class="card-header-icon">
          <button
            v-tooltip="{ content: 'Προβολή' }"
            v-if="isEditing"
            class="button is-small"
            @click="isEditing = !isEditing"
          >
            <span class="icon is-small"><i class="fa fa-eye"/></span>
          </button>
          <button
            v-tooltip="{ content: 'Επεξεργασία' }"
            v-else
            :disabled="isDeleted"
            class="button is-small"
            @click="isEditing = !isEditing"
          >
            <span class="icon is-small"><i class="fa fa-pencil"/></span>
          </button>
        </div>
      </div>
      <div v-if="isEditing">
        <form @submit.prevent="submit">
          <div class="card-content">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Email *</label>
                  <div class="control">
                    <input
                      v-validate="'required|email'"
                      v-model="newOrder.email"
                      type="email"
                      class="input"
                      name="email"
                    />
                  </div>
                  <div v-show="errors.has('email')" class="help is-danger">
                    Εισάγετε έγκυρο email
                  </div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column is-half">
                <div
                  v-for="type in invoiceTypes"
                  :key="type.key"
                  class="is-inline-block control radio-control"
                >
                  <radio
                    :value="type.key"
                    v-model="invoiceType"
                    name="invoiceType"
                  />
                  <label>
                    {{ type.label }}
                  </label>
                </div>
              </div>
            </div>
            <div class="columns columns__address">
              <div class="column">
                <div class="field">
                  <label class="label">Όνομα *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      v-model.trim="newAddress.firstName"
                      type="text"
                      class="input"
                      name="firstName"
                    />
                  </div>
                  <div v-show="errors.has('firstName')" class="help is-danger">
                    Εισάγετε όνομα
                  </div>
                </div>

                <div class="field">
                  <label class="label">Επώνυμο *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      v-model.trim="newAddress.lastName"
                      type="text"
                      class="input"
                      name="lastName"
                    />
                  </div>
                  <div v-show="errors.has('lastName')" class="help is-danger">
                    Εισάγετε επώνυμο
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="label">Κιν. Τηλέφωνο *</label>
                  <div class="control">
                    <vue-tel-input
                      v-validate="'required'"
                      v-model="phoneNumbers.mobile"
                      enabled-country-code
                      placeholder="Εισάγετε Κινητό"
                      name="mobile"
                      input-id="edit_order_address_mobile"
                      @input="onInputMobile"
                    />
                    <p
                      v-show="
                        errors.has('mobile') || errors.has('mobile-invalid')
                      "
                      class="help is-danger"
                    >
                      Εισάγετε έγκυρο κινητό τηλέφωνο
                    </p>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Σταθ. Τηλέφωνο</label>
                  <div class="control">
                    <vue-tel-input
                      v-model="phoneNumbers.telephone"
                      enabled-country-code
                      input-id="edit_order_address_telephone"
                      name="telephone"
                      placeholder="Εισάγετε Σταθερό"
                      @input="onInputTelephone"
                    />
                    <p v-show="errors.has('telephone')" class="help is-danger">
                      Εισάγετε έγκυρο σταθερό τηλέφωνο
                    </p>
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="label">Οδός *</label>
                  <div class="control">
                    <GmapAutocomplete
                      v-validate="'required'"
                      :value="newAddress.street"
                      :options="{
                        types: ['geocode'],
                        componentRestrictions: {
                          country: ['gr', 'cy'],
                        },
                      }"
                      type="text"
                      class="input"
                      name="street"
                      placeholder="Εισάγετε Οδό, Αριθμό & Πόλη"
                      autocomplete="new-password"
                      @focus.native="resetAutocomplete"
                      @keydown.enter.prevent.native
                      @place_changed="updatePlace"
                      @input.native="handleStreetChange"
                    />
                  </div>
                  <div v-show="errors.has('street')" class="help is-danger">
                    Εισάγετε Οδό, Αριθμό & Πόλη
                  </div>
                </div>

                <div class="field">
                  <label class="label">Αριθμός *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      :value="newAddress.streetNumber"
                      class="input"
                      type="text"
                      name="streetNumber"
                      placeholder="Εισάγετε Aριθμό"
                      autocomplete="new-password"
                      @focus="resetAutocomplete"
                      @input="updateAddress"
                    />
                  </div>
                  <div
                    v-show="errors.has('streetNumber')"
                    class="help is-danger"
                  >
                    Εισάγετε αριθμό
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="label">ΤΚ *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      :value="newAddress.postcode"
                      class="input"
                      name="postcode"
                      placeholder="Εισάγετε ΤΚ"
                      autocomplete="new-password"
                      @focus="resetAutocomplete"
                      @input="updateAddress"
                    />
                  </div>
                  <div v-show="errors.has('postcode')" class="help is-danger">
                    Εισάγετε τ. κώδικα
                  </div>
                </div>

                <div class="field">
                  <label class="label">Πόλη *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      :value="newAddress.city"
                      class="input"
                      name="city"
                      placeholder="Εισάγετε Πόλη"
                      autocomplete="new-password"
                      @focus="resetAutocomplete"
                      @input="updateAddress"
                    />
                  </div>
                  <div v-show="errors.has('city')" class="help is-danger">
                    Εισάγετε πόλη
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="label">Νομός *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      :value="newAddress.region"
                      class="input"
                      name="region"
                      placeholder="Εισάγετε Νομό"
                      autocomplete="new-password"
                      @focus="resetAutocomplete"
                      @input="updateAddress"
                    />
                  </div>
                  <div v-show="errors.has('region')" class="help is-danger">
                    Εισάγετε νομό
                  </div>
                </div>

                <div class="field">
                  <label class="label">Χώρα *</label>
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select
                        v-validate="'required'"
                        ref="country"
                        :value="newAddress.country"
                        name="country"
                        @change="updateAddress"
                      >
                        <option
                          v-for="(country, index) in countryOptions"
                          :key="index"
                          :value="country.value"
                        >
                          {{ country.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="invoiceSelected" class="columns">
              <div class="column is-one-fifth">
                <div class="field">
                  <label class="label">Εταιρεία *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      v-model.trim="newInvoice.company"
                      type="text"
                      class="input"
                      name="company"
                    />
                  </div>
                  <div v-show="errors.has('company')" class="help is-danger">
                    Εισάγετε εταιρεία
                  </div>
                </div>
              </div>

              <div class="column is-one-fifth">
                <div class="field">
                  <label class="label">Ιδιότητα *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      v-model.trim="newInvoice.profession"
                      type="text"
                      class="input"
                      name="profession"
                    />
                  </div>
                  <div v-show="errors.has('profession')" class="help is-danger">
                    Εισάγετε ιδιότητα
                  </div>
                </div>
              </div>

              <div class="column is-one-fifth">
                <div class="field">
                  <label class="label">ΑΦΜ *</label>
                  <div class="control">
                    <input
                      v-validate="'required'"
                      v-model.trim="newInvoice.vat_number"
                      type="text"
                      class="input"
                      name="vat_number"
                    />
                  </div>
                  <div v-show="errors.has('vat_number')" class="help is-danger">
                    Εισάγετε ΑΦΜ
                  </div>
                </div>
              </div>

              <div class="column is-two-fifths">
                <div class="field">
                  <label class="label">{{ vatOfficeLabel }}</label>
                  <div class="control">
                    <multiselect
                      v-validate="'required_if:country,gr'"
                      v-model="vatOffice"
                      :options="vatOffices"
                      :searchable="true"
                      :show-labels="false"
                      name="vat_office"
                      track-by="id"
                      label="title"
                      placeholder="Επιλέξτε Δ.Ο.Υ."
                      selected-label="Επιλεγμένο"
                      select-label="Πατήστε enter για επιλογή"
                      deselect-label="Πατήστε enter για απο-επιλογή"
                      @select="handleSelectVatOffice"
                      @remove="handleRemoveVatOffice"
                    />
                  </div>
                  <div v-show="errors.has('vat_office')" class="help is-danger">
                    Εισάγετε ΔΟΥ
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <FormSubmit :is-saving="isSaving" @reset="reset" />
          </div>
        </form>
      </div>
      <div v-else class="card-content">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            {{ order.email }}
          </div>
        </div>

        <div class="columns columns__address">
          <div class="column">
            <div class="field">
              <label class="label">Όνομα</label>
              <div class="control">
                {{ newAddress.firstName }}
              </div>
            </div>

            <div class="field">
              <label class="label">Επώνυμο</label>
              <div class="control">
                {{ newAddress.lastName }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Κιν. Τηλέφωνο</label>
              <div class="control">
                {{ newAddress.mobile }}
              </div>
            </div>

            <div class="field">
              <label class="label">Σταθ. Τηλέφωνο</label>
              <div class="control">
                {{ newAddress.telephone }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Οδός</label>
              <div class="control">
                {{ newAddress.street }}
              </div>
            </div>

            <div class="field">
              <label class="label">Αριθμός</label>
              <div class="control">
                {{ newAddress.streetNumber }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Τ. Κώδικας</label>
              <div class="control">
                {{ newAddress.postcode }}
              </div>
            </div>

            <div class="field">
              <label class="label">Πόλη</label>
              <div class="control">
                {{ newAddress.city }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Νομός</label>
              <div class="control">
                {{ newAddress.region }}
              </div>
            </div>

            <div class="field">
              <label class="label">Χώρα</label>
              <div class="control">
                <template v-if="newAddress.country === 'gr'">Ελλάδα</template>
                <template v-else-if="newAddress.country === 'cy'">
                  Κύπρος
                </template>
                <template v-else>{{ newAddress.country }}</template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="invoiceSelected" class="columns">
          <div class="column is-one-fifth">
            <div class="field">
              <label class="label">Εταιρεία</label>
              <div class="control">
                {{ newInvoice.company }}
              </div>
            </div>
          </div>

          <div class="column is-one-fifth">
            <div class="field">
              <label class="label">Ιδιότητα</label>
              <div class="control">
                {{ newInvoice.profession }}
              </div>
            </div>
          </div>

          <div class="column is-one-fifth">
            <div class="field">
              <label class="label">ΑΦΜ</label>
              <div class="control">
                {{ newInvoice.vat_number }}
              </div>
            </div>
          </div>

          <div class="column is-two-fifths">
            <div class="field">
              <label class="label">Δ.Ο.Υ</label>
              <div v-if="vatOffice" class="control">
                {{ vatOffice.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, pick } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import gMapAutocomplete from '@/utils/gMapAutocomplete';
import transformations from '@/utils/transformations';
import {
  calculateProductsSum,
  calculateSendCharges,
} from '@/utils/calculations';
import AddressMixin from '@/mixins/Address';
import EditItem from '@/views/components/EditItem';
import { MIN_COST_FOR_FREE_SHIPPING } from '@/constants/costs';

export default {
  components: {
    Multiselect,
  },

  extends: EditItem,

  mixins: [AddressMixin],

  props: {
    isDeleted: Boolean,
    order: Object,

    vatOffices: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      newAddress: {},
      newInvoice: null,
      newOrder: {},
      invoiceTypes: [
        {
          key: 'no_invoice',
          label: 'Απόδειξη',
        },
        {
          key: 'invoice',
          label: 'Τιμολόγιο',
        },
      ],
      invoiceType: 'no_invoice',
      vatOffice: null,
      isEditing: false,
      isSaving: false,
      phoneNumbers: {
        mobile: '',
        telephone: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      sendCharges: 'getSendCharges',
      sendMethods: 'getSendMethods',
    }),

    invoiceSelected() {
      return this.invoiceType === 'invoice';
    },

    isPharmacy() {
      const sendMethod = this.sendMethods.find(
        ({ id }) => id === this.newOrder.send_method_id,
      );

      return sendMethod?.slug === 'pharmacy';
    },

    isBoxnow() {
      const sendMethod = this.sendMethods.find(
        ({ id }) => id === this.newOrder.send_method_id,
      );

      return sendMethod?.slug === 'box_now';
    },

    country() {
      return this.$options.filters.capitalizeGreek(
        this.newAddress.country || '',
      );
    },

    isVatOfficeRequired() {
      return this.country !== 'cy';
    },

    vatOfficeLabel() {
      let label = 'ΔΟΥ';
      if (this.isVatOfficeRequired) {
        label += ' *';
      }

      return label;
    },
  },

  watch: {
    order: {
      handler(newValue) {
        this.newOrder = cloneDeep(newValue);

        this.newAddress = transformations.capitalizeAddress(
          pick(this.newOrder.charge_address, [
            'firstName',
            'lastName',
            'telephone',
            'mobile',
            'postcode',
            'street',
            'streetNumber',
            'city',
            'region',
            'country',
          ]),
        );

        if (!isEmpty(this.newOrder.invoice)) {
          this.invoiceType = 'invoice';
        } else {
          this.invoiceType = 'no_invoice';
        }

        this.phoneNumbers.mobile = this.newAddress.mobile || '';
        this.phoneNumbers.telephone = this.newAddress.telephone || '';
      },
      immediate: true,
    },

    country: {
      handler() {
        this.updateSendCharges();
      },
      immediate: true,
    },

    invoiceSelected: {
      handler(newVal) {
        if (newVal) {
          if (!isEmpty(this.newOrder.invoice)) {
            if (!isEmpty(this.vatOffices)) {
              const vatId = Number(this.newOrder.invoice.vat_office_id);

              this.vatOffice = this.vatOffices.find(
                vatOffice => vatOffice.id === vatId,
              );
            }

            this.newInvoice = transformations.capitalizeAddress({
              ...pick(this.newOrder.invoice, [
                'firstName',
                'lastName',
                'telephone',
                'mobile',
                'postcode',
                'street',
                'streetNumber',
                'city',
                'region',
                'country',
                'company',
                'profession',
                'vat_number',
                'vat_office_id',
              ]),
              vat_office: this.vatOffice,
            });
          } else {
            this.newInvoice = {
              ...pick(this.newAddress, [
                'firstName',
                'lastName',
                'telephone',
                'mobile',
                'street',
                'streetNumber',
                'postcode',
                'city',
                'region',
                'country',
              ]),
              company: '',
              profession: '',
              vat_number: '',
              vat_office: null,
              vat_office_id: null,
            };
            this.vatOffice = null;
          }
        } else {
          this.newInvoice = null;
          this.vatOffice = null;
        }

        this.$validator.pause();
        this.$nextTick(() => {
          this.$validator.reset();
          this.$validator.resume();
        });
      },
      immediate: true,
    },

    vatOffices() {
      if (!isEmpty(this.order.invoice)) {
        const vatId = Number(this.order.invoice.vat_office_id);

        const newVatOffice = this.vatOffices.find(
          vatOffice => vatOffice.id === vatId,
        );

        this.vatOffice = newVatOffice || null;
      }
    },
  },

  methods: {
    ...mapActions([
      'updateOrderAddress',
      'attachOrderInvoice',
      'updateOrderInvoice',
      'detachOrderInvoice',
    ]),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await Promise.resolve()
          .then(() => {
            this.updateOrderAddress({
              charge_address: {
                ...this.newAddress,
              },
              send_charge: this.newOrder.send_charge,
              email: this.newOrder.email,
            });
          })
          .then(() => {
            if (this.invoiceSelected) {
              if (this.order.invoice) {
                // update existing invoice
                this.updateOrderInvoice({
                  invoice: {
                    ...this.order.invoice,
                    ...this.newInvoice,
                  },
                });
              } else {
                // create new invoice and attach it
                this.attachOrderInvoice({
                  order: this.order,
                  invoice: {
                    ...this.newInvoice,
                    customer_id: this.order.orderable.customer.id,
                  },
                });
              }
            } else if (!this.invoiceSelected && !isEmpty(this.order.invoice)) {
              // detach invoice from an order
              this.detachOrderInvoice({
                invoice: this.order.invoice,
              });
            }
          });

        this.isSaving = false;
        this.isEditing = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η διεύθυνση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newOrder = cloneDeep(this.order);

      this.newAddress = transformations.capitalizeAddress(
        pick(this.newOrder.charge_address, [
          'firstName',
          'lastName',
          'telephone',
          'mobile',
          'postcode',
          'street',
          'streetNumber',
          'city',
          'region',
          'country',
        ]),
      );

      if (!isEmpty(this.newOrder.invoice)) {
        this.newInvoice = transformations.capitalizeAddress(
          pick(this.newOrder.invoice, [
            'firstName',
            'lastName',
            'telephone',
            'mobile',
            'postcode',
            'street',
            'streetNumber',
            'city',
            'region',
            'country',
            'company',
            'profession',
            'vat_number',
            'vat_office_id',
          ]),
        );

        const vatId = Number(this.newInvoice.vat_office_id);

        this.vatOffice = this.vatOffices.find(
          vatOffice => vatOffice.id === vatId,
        );

        this.newInvoice = {
          ...this.newInvoice,
          vat_office: this.vatOffice,
        };

        this.invoiceType = 'invoice';
      } else {
        this.newInvoice = null;
        this.vatOffice = null;
        this.invoiceType = 'no_invoice';
      }

      this.phoneNumbers.mobile = this.newAddress.mobile || '';
      this.phoneNumbers.telephone = this.newAddress.telephone || '';

      this.errors.remove('telephone');
      this.errors.remove('mobile-invalid');

      this.$nextTick(() => {
        this.$validator.validateAll();
      });
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.newAddress = {
          ...this.newAddress,
          telephone: number.international,
        };
        this.errors.remove('telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.newAddress = {
          ...this.newAddress,
          mobile: number.international,
        };
        this.errors.remove('mobile-invalid');
      }
    },

    /* eslint-disable camelcase */
    updateAddress(e) {
      const { name } = e.target;

      this.newAddress = {
        ...this.newAddress,
        [name]: e.target.value,
      };
    },

    updatePlace(place) {
      const newPlace = gMapAutocomplete.setPlace(place);

      this.newAddress = {
        ...this.newAddress,
        ...newPlace,
      };
    },

    handleSelectVatOffice(selectedOption) {
      this.newInvoice = {
        ...this.newInvoice,
        vat_office: selectedOption,
        vat_office_id: selectedOption.id,
      };
    },

    handleRemoveVatOffice() {
      this.newInvoice = {
        ...this.newInvoice,
        vat_office: null,
        vat_office_id: null,
      };
    },

    updateSendCharges() {
      if (!isEmpty(this.newAddress)) {
        let country;
        if (isEmpty(this.newOrder.send_address)) {
          ({ country } = this.newAddress);
        } else {
          ({ country } = this.newOrder.send_address);
        }

        if (country) {
          const isCyprus = country === 'cy';
          const boxNowCharge = isCyprus
            ? this.sendCharges.cy.box_now.charge
            : this.sendCharges.gr.box_now.charge;

          let send_charge = 0;

          if (this.isPharmacy) {
            send_charge = 0;
          } else if (this.isBoxnow) {
            const { sum } = calculateProductsSum(this.newOrder.products);
            send_charge = sum >= MIN_COST_FOR_FREE_SHIPPING ? 0 : boxNowCharge;
          } else {
            const { sum, weight } = calculateProductsSum(
              this.newOrder.products,
            );
            send_charge = calculateSendCharges(
              sum,
              weight,
              isCyprus ? this.sendCharges.cy : this.sendCharges.gr,
            );
          }

          this.newOrder = {
            ...this.newOrder,
            send_charge,
          };
        }
      }
    },

    handleStreetChange($event) {
      const { name, value } = $event.target;

      this.newAddress = {
        ...this.newAddress,
        street: value,
      };

      this.$nextTick(() => {
        this.$validator.validate(name);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-component,
.label {
  display: inline-block;
}

.radio-component {
  margin-right: 10px;
  vertical-align: middle;
}

.radio-control + .radio-control {
  margin-left: 1.5rem;
}

.columns__address {
  margin-bottom: 0;
}

.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
