<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeModal()" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Επεξεργασία Τιμολογίου</p>
        <button class="delete" @click="closeModal()" />
      </header>
      <form @submit.prevent="submit">
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Εταιρεία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.company"
                    type="text"
                    class="input"
                    name="company"
                  />
                </div>
                <div v-show="errors.has('company')" class="help is-danger">
                  Εισάγετε εταιρεία
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Ιδιότητα *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.profession"
                    type="text"
                    class="input"
                    name="profession"
                  />
                </div>
                <div v-show="errors.has('profession')" class="help is-danger">
                  Εισάγετε ιδιότητα
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">ΑΦΜ *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.vat_number"
                    type="text"
                    class="input"
                    name="vat_number"
                  />
                </div>
                <div v-show="errors.has('vat_number')" class="help is-danger">
                  Εισάγετε ΑΦΜ
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">ΔΟΥ *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.vat_office"
                    type="text"
                    class="input"
                    name="vat_office"
                  />
                </div>
                <div v-show="errors.has('vat_office')" class="help is-danger">
                  Εισάγετε ΔΟΥ
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Όνομα *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.firstName"
                    type="text"
                    class="input"
                    name="firstName"
                  />
                </div>
                <div v-show="errors.has('firstName')" class="help is-danger">
                  Εισάγετε όνομα
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Επώνυμο *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.lastName"
                    type="text"
                    class="input"
                    name="lastName"
                  />
                </div>
                <div v-show="errors.has('lastName')" class="help is-danger">
                  Εισάγετε επώνυμο
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Κιν. Τηλέφωνο *</label>
                <div class="control">
                  <vue-tel-input
                    v-validate="'required'"
                    v-model="phoneNumbers.mobile"
                    enabled-country-code
                    placeholder="Εισάγετε Κινητό"
                    name="mobile"
                    input-id="edit_customer_invoice_mobile"
                    @input="onInputMobile"
                  />
                  <p
                    v-show="
                      errors.has('mobile') || errors.has('mobile-invalid')
                    "
                    class="help is-danger"
                  >
                    Εισάγετε έγκυρο κινητό τηλέφωνο
                  </p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Σταθ. Τηλέφωνο</label>
                <div class="control">
                  <vue-tel-input
                    v-model="phoneNumbers.telephone"
                    enabled-country-code
                    input-id="edit_customer_invoice_telephone"
                    name="telephone"
                    placeholder="Εισάγετε Σταθερό"
                    @input="onInputTelephone"
                  />
                  <p v-show="errors.has('telephone')" class="help is-danger">
                    Εισάγετε έγκυρο σταθερό τηλέφωνο
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Οδός *</label>
                <div class="control">
                  <GmapAutocomplete
                    v-validate="'required'"
                    v-model.lazy="newInvoice.street"
                    :options="{
                      types: ['geocode'],
                      componentRestrictions: {
                        country: ['gr', 'cy'],
                      },
                    }"
                    class="input"
                    type="text"
                    name="street"
                    placeholder="Εισάγετε Οδό, Αριθμό & Πόλη"
                    autocomplete="new-password"
                    @focus.native="resetAutocomplete"
                    @keydown.enter.prevent.native
                    @place_changed="updatePlace"
                    @input.native="handleStreetChange"
                  />
                </div>
                <div v-show="errors.has('street')" class="help is-danger">
                  Εισάγετε Οδό, Αριθμό & Πόλη
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Αριθμός *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.streetNumber"
                    type="number"
                    class="input"
                    name="streetNumber"
                  />
                </div>
                <div v-show="errors.has('streetNumber')" class="help is-danger">
                  Εισάγετε αριθμό
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">ΤΚ *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.postcode"
                    type="text"
                    class="input"
                    name="postcode"
                  />
                </div>
                <div v-show="errors.has('postcode')" class="help is-danger">
                  Εισάγετε τ. κώδικα
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Πόλη *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.city"
                    type="text"
                    class="input"
                    name="city"
                  />
                </div>
                <div v-show="errors.has('city')" class="help is-danger">
                  Εισάγετε πόλη
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Νομός *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newInvoice.region"
                    type="text"
                    class="input"
                    name="region"
                  />
                </div>
                <div v-show="errors.has('region')" class="help is-danger">
                  Εισάγετε νομό
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Χώρα *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-validate="'required'"
                      v-model.trim="newInvoice.country"
                      name="country"
                    >
                      <option
                        v-for="(country, index) in countryOptions"
                        :key="index"
                        :value="country.value"
                      >
                        {{ country.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import _pick from 'lodash/pick';
import { mapActions } from 'vuex';
import FormSubmit from '@/views/components/FormSubmit';
import gMapAutocomplete from '@/utils/gMapAutocomplete';
import AddressMixin from '@/mixins/Address';

export default {
  components: {
    FormSubmit,
  },

  mixins: [AddressMixin],

  props: {
    isOpen: false,
    customer: Object,
    invoice: Object,
  },

  data() {
    return {
      newInvoice: {},
      isSaving: false,
      phoneNumbers: {
        mobile: this.invoice.mobile,
        telephone: this.invoice.telephone,
      },
    };
  },

  watch: {
    invoice(newValue) {
      this.newInvoice = _pick(newValue, [
        'company',
        'profession',
        'vat_number',
        'vat_office',
        'firstName',
        'lastName',
        'telephone',
        'mobile',
        'postcode',
        'street',
        'streetNumber',
        'city',
        'region',
        'country',
      ]);
    },
  },

  methods: {
    ...mapActions(['updateCustomerInvoice']),

    closeModal() {
      this.reset();
      this.$emit('closeModal');
    },

    handleStreetChange($event) {
      const { name, value } = $event.target;

      this.newInvoice = {
        ...this.newInvoice,
        street: value,
      };

      this.$nextTick(() => {
        this.$validator.validate(name);
      });
    },

    updatePlace(place) {
      const newPlace = gMapAutocomplete.setPlace(place);

      this.newInvoice = {
        ...this.newInvoice,
        ...newPlace,
      };
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.newInvoice.telephone = number.international;
        this.errors.remove('telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.newInvoice.mobile = number.international;
        this.errors.remove('mobile-invalid');
      }
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateCustomerInvoice({
          invoice: {
            ...this.invoice,
            ...this.newInvoice,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το τιμολόγιο αποθηκεύτηκε',
        });

        this.closeModal();
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newInvoice = _pick(this.invoice, [
        'company',
        'profession',
        'vat_number',
        'vat_office',
        'firstName',
        'lastName',
        'telephone',
        'mobile',
        'postcode',
        'street',
        'streetNumber',
        'city',
        'region',
        'country',
      ]);

      this.errors.remove('telephone');
      this.errors.remove('mobile-invalid');

      this.$validator.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
