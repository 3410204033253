<template>
  <div class="card hidden-print">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Στοιχεία Αποστολής
        </div>
      </div>
    </div>
    <div>
      <div class="card-content">
        <div class="field">
          <label class="label">Επέλεξε Διεύθυνση Αποστολής *</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-validate="'required'"
                v-model="send_address_id"
                name="send_address_id"
              >
                <option
                  v-for="item in billing"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <template v-if="differentAddressSelected">
          <hr />
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Όνομα *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.firstName"
                    type="text"
                    class="input"
                    name="send_firstName"
                    @input="updateAddress"
                  />
                </div>
                <div
                  v-show="errors.has('send_firstName')"
                  class="help is-danger"
                >
                  Εισάγετε όνομα
                </div>
              </div>

              <div class="field">
                <label class="label">Επώνυμο *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.lastName"
                    type="text"
                    class="input"
                    name="send_lastName"
                    @input="updateAddress"
                  />
                </div>
                <div
                  v-show="errors.has('send_lastName')"
                  class="help is-danger"
                >
                  Εισάγετε επώνυμο
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Κινητό*</label>
                <div class="control">
                  <vue-tel-input
                    v-validate="'required'"
                    :value="phoneNumbers.mobile"
                    enabled-country-code
                    placeholder="Εισάγετε Κινητό"
                    name="send_mobile"
                    input-id="add_order_send_mobile"
                    @input="onInputMobile"
                  />
                  <p
                    v-show="
                      errors.has('send_mobile') ||
                        errors.has('send_mobile-invalid')
                    "
                    class="help is-danger"
                  >
                    Εισάγετε έγκυρο κινητό τηλέφωνο
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Σταθερό</label>
                <div class="control">
                  <vue-tel-input
                    :value="phoneNumbers.telephone"
                    enabled-country-code
                    input-id="add_order_send_telephone"
                    name="send_telephone"
                    placeholder="Εισάγετε Σταθερό"
                    @input="onInputTelephone"
                  />
                  <p
                    v-show="errors.has('send_telephone')"
                    class="help is-danger"
                  >
                    Εισάγετε έγκυρο σταθερό τηλέφωνο
                  </p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Οδός *</label>
                <div class="control">
                  <GmapAutocomplete
                    v-validate="'required'"
                    :value="newAddress.street"
                    :options="{
                      types: ['geocode'],
                      componentRestrictions: {
                        country: ['gr', 'cy'],
                      },
                    }"
                    class="input"
                    type="text"
                    name="send_street"
                    placeholder="Εισάγετε Οδό, Αριθμό & Πόλη"
                    autocomplete="new-password"
                    @focus.native="resetAutocomplete"
                    @keydown.enter.prevent.native
                    @place_changed="updatePlace"
                    @input.native="handleStreetChange"
                  />
                </div>
                <div v-show="errors.has('send_street')" class="help is-danger">
                  Εισάγετε Οδό, Αριθμό & Πόλη
                </div>
              </div>

              <div class="field">
                <label class="label">Αριθμός *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.streetNumber"
                    class="input"
                    type="text"
                    name="send_streetNumber"
                    placeholder="Εισάγετε Aριθμό"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                    @input="updateAddress"
                  />
                </div>
                <div
                  v-show="errors.has('send_streetNumber')"
                  class="help is-danger"
                >
                  Εισάγετε αριθμό
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">ΤΚ *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.postcode"
                    class="input"
                    name="send_postcode"
                    placeholder="Εισάγετε ΤΚ"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                    @input="updateAddress"
                  />
                </div>
                <div
                  v-show="errors.has('send_postcode')"
                  class="help is-danger"
                >
                  Εισάγετε ΤΚ
                </div>
              </div>

              <div class="field">
                <label class="label">Πόλη *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.city"
                    class="input"
                    name="send_city"
                    placeholder="Εισάγετε Πόλη"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                    @input="updateAddress"
                  />
                </div>
                <div v-show="errors.has('send_city')" class="help is-danger">
                  Εισάγετε πόλη
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Νομός *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.region"
                    class="input"
                    name="send_region"
                    placeholder="Εισάγετε Νομό"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                    @input="updateAddress"
                  />
                </div>
                <div v-show="errors.has('send_region')" class="help is-danger">
                  Εισάγετε νομό
                </div>
              </div>

              <div class="field">
                <label class="label">Χώρα *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-validate="'required'"
                      :value="newAddress.country"
                      name="send_country"
                      @input="updateAddress"
                    >
                      <option
                        v-for="(country, index) in countryOptions"
                        :key="index"
                        :value="country.value"
                      >
                        {{ country.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { clone, isEmpty } from 'lodash';
import EditItem from '@/views/components/EditItem';
import gMapAutocomplete from '@/utils/gMapAutocomplete';
import {
  calculateSendCharges,
  calculateProductsSum,
} from '@/utils/calculations';
import CHECKOUT_CHARGES from '@/constants/checkoutCharges';
import AddressMixin from '@/mixins/Address';
import { MIN_COST_FOR_FREE_SHIPPING } from '@/constants/costs';

const BILLING = {
  1: { value: 'same_address', text: 'Διεύθυνση Χρέωσης' },
  2: { value: 'different_address', text: 'Διαφορετική Διεύθυνση' },
};

export default {
  inject: ['$validator'],

  extends: EditItem,

  mixins: [AddressMixin],

  props: {
    order: Object,
  },

  data() {
    return {
      billing: BILLING,
      phoneNumbers: {
        mobile: '',
        telephone: '',
      },
      send_address_id: 'same_address',
    };
  },

  computed: {
    ...mapGetters({
      newOrder: 'getNewOrder',
      sendCharges: 'getSendCharges',
      isPharmacy: 'getIsPharmacy',
      isBoxnow: 'getIsBoxnow',
      isPayOnDelivery: 'getIsPayOnDelivery',
    }),

    differentAddressSelected() {
      return this.send_address_id === 'different_address';
    },

    newAddress() {
      if (!this.differentAddressSelected) {
        return null;
      }
      if (this.newOrder.send_address) {
        return clone(this.newOrder.send_address);
      }

      return {
        firstName: '',
        lastName: '',
        telephone: '',
        mobile: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        region: '',
        country: 'gr',
      };
    },
  },

  watch: {
    newAddress: {
      handler(newVal) {
        if (isEmpty(newVal)) {
          this.phoneNumbers = {
            mobile: '',
            telephone: '',
          };
        }
      },
      immediate: true,
    },

    differentAddressSelected: {
      handler(newVal) {
        if (!newVal) {
          this.updateNewOrder({
            send_address: null,
          });
        }

        this.updateCharges();
      },
    },

    'newAddress.country': {
      handler() {
        this.updateCharges();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      updateNewOrder: 'updateNewOrder',
      updateCountry: 'toggleCountry',
    }),

    handleStreetChange($event) {
      const { name, value } = $event.target;

      this.updateNewOrder({
        send_address: {
          ...this.newAddress,
          street: value,
        },
      });

      this.$nextTick(() => {
        this.$validator.validate(name);
      });
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'send_telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.updateNewOrder({
          send_address: {
            ...this.newAddress,
            telephone: number.international || '',
          },
        });
        this.phoneNumbers.telephone = number.national || '';
        this.errors.remove('send_telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'send_mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.errors.remove('send_mobile-invalid');
        this.updateNewOrder({
          send_address: {
            ...this.newAddress,
            mobile: number.international || '',
          },
        });

        this.phoneNumbers.mobile = number.national || '';
      }
    },

    /* eslint-disable camelcase */
    updateAddress(e) {
      const { name, value } = e.target;

      const strippedName = name.replace('send_', '');

      this.updateNewOrder({
        send_address: {
          ...this.newAddress,
          [strippedName]: value,
        },
      });
    },

    updatePlace(place) {
      const newPlace = gMapAutocomplete.setPlace(place);

      this.updateNewOrder({
        send_address: {
          ...this.newAddress,
          ...newPlace,
        },
      });
    },

    updateCharges() {
      if (!isEmpty(this.newOrder.charge_address)) {
        let country;
        if (!this.differentAddressSelected) {
          ({ country } = this.newOrder.charge_address);
        } else if (!isEmpty(this.newOrder.send_address)) {
          ({ country } = this.newOrder.send_address);
        }

        if (country) {
          const isCyprus = country === 'cy';
          const boxNowCharge = isCyprus
            ? this.sendCharges.cy.box_now.charge
            : this.sendCharges.gr.box_now.charge;

          this.updateCountry(isCyprus);

          let send_charge = 0;
          let checkoutCharge = this.newOrder.checkout_charge;

          if (this.isPharmacy) {
            send_charge = 0;
          } else if (this.isBoxnow) {
            const { sum } = calculateProductsSum(this.newOrder.products);
            send_charge = sum >= MIN_COST_FOR_FREE_SHIPPING ? 0 : boxNowCharge;
          } else {
            const { sum, weight } = calculateProductsSum(
              this.newOrder.products,
            );
            send_charge = calculateSendCharges(
              sum,
              weight,
              isCyprus ? this.sendCharges.cy : this.sendCharges.gr,
            );
          }

          if (this.isPayOnDelivery) {
            checkoutCharge = isCyprus
              ? CHECKOUT_CHARGES.cyprus
              : CHECKOUT_CHARGES.greece;
          }

          this.updateNewOrder({
            send_charge,
            checkout_charge: checkoutCharge,
          });

          this.updateNewOrder({
            send_charge,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
