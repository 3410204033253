export default {
  data() {
    return {
      countryOptions: [
        {
          value: 'gr',
          text: 'ΕΛΛΑΔΑ',
        },
        {
          value: 'cy',
          text: 'ΚΥΠΡΟΣ',
        },
      ],
    };
  },

  methods: {
    resetAutocomplete($event) {
      $event.target.setAttribute('autocomplete', 'new-password');
    },
  },
};
