<template>
  <div class="card hidden-print">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Πληροφορίες
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Μέθοδος Πληρωμής *</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  v-validate="'required'"
                  v-model.trim.number="order.checkout_method_id"
                  name="checkout_method_id"
                >
                  <option :value="null">Επέλεξε μέθοδο πληρωμής</option>
                  <option
                    v-for="item in checkoutMethods"
                    :key="item.id"
                    :value="item.id"
                    :disabled="item.active === false"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-show="errors.has('checkout_method_id')"
              class="help is-danger"
            >
              Επέλεξε μέθοδο πληρωμής
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Μέθοδος Αποστολής *</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  v-validate="'required'"
                  v-model.trim.number="order.send_method_id"
                  name="send_method_id"
                >
                  <option :value="null">Επέλεξε μέθοδο αποστολής</option>
                  <option
                    v-for="item in sendMethods"
                    :key="item.id"
                    :value="item.id"
                    :disabled="item.active === false"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
            </div>
            <div v-show="errors.has('send_method_id')" class="help is-danger">
              Επέλεξε μέθοδο αποστολής
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Μέθοδος Παραγγελίας</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  v-model.trim.number="order.order_method_id"
                  name="order_method_id"
                >
                  <option :value="null">Επέλεξε μέθοδο παραγγελίας</option>
                  <option
                    v-for="item in orderMethods"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { isEmpty } from 'lodash';
import {
  calculateProductsSum,
  calculateSendCharges,
} from '@/utils/calculations';
import CHECKOUT_CHARGES from '@/constants/checkoutCharges';
import { MIN_COST_FOR_FREE_SHIPPING } from '@/constants/costs';

export default {
  inject: ['$validator'],
  props: {
    order: Object,
  },
  computed: {
    ...mapGetters({
      checkoutMethods: 'checkoutMethods/list/getCheckoutMethods',
      sendMethods: 'getSendMethods',
      orderMethods: 'getOrderMethods',
      sendCharges: 'getSendCharges',
    }),
  },
  /* eslint-disable camelcase */
  watch: {
    // eslint-disable-next-line func-names
    'order.send_method_id': {
      handler(newValue) {
        if (!isEmpty(this.order.charge_address)) {
          const send_method = this.sendMethods.find(
            ({ id }) => id === newValue,
          );

          let country;

          if (isEmpty(this.order.send_address)) {
            ({ country } = this.order.charge_address);
          } else {
            ({ country } = this.order.send_address);
          }

          if (country) {
            const isCyprus = country === 'cy';
            const boxNowCharge = isCyprus
              ? this.sendCharges.cy.box_now.charge
              : this.sendCharges.gr.box_now.charge;

            let send_charge = 0;

            if (send_method.slug === 'pharmacy') {
              send_charge = 0;
            } else if (send_method.slug === 'box_now') {
              const { sum } = calculateProductsSum(this.order.products);
              send_charge =
                sum >= MIN_COST_FOR_FREE_SHIPPING ? 0 : boxNowCharge;
            } else {
              const { sum, weight } = calculateProductsSum(this.order.products);
              send_charge = calculateSendCharges(
                sum,
                weight,
                isCyprus ? this.sendCharges.cy : this.sendCharges.gr,
              );
            }

            this.updateNewOrder({
              send_method_id: newValue,
              send_method,
              send_charge,
            });
          }
        }
      },
    },
    // eslint-disable-next-line func-names
    'order.checkout_method_id': function(newValue) {
      if (newValue) {
        const checkout_method = this.checkoutMethods.find(
          ({ id }) => id === newValue,
        );

        let checkoutCharge = 0;
        if (checkout_method.slug === 'pay_on_delivery') {
          let country;

          if (isEmpty(this.order.send_address)) {
            ({ country } = this.order.charge_address);
          } else {
            ({ country } = this.order.send_address);
          }

          if (country) {
            const isCyprus = country === 'cy';

            checkoutCharge = isCyprus
              ? CHECKOUT_CHARGES.cyprus
              : CHECKOUT_CHARGES.greece;
          }
        }

        this.updateNewOrder({
          checkout_method_id: newValue,
          checkout_method,
          checkout_charge: checkoutCharge,
        });
      }
    },
    // eslint-disable-next-line func-names
    'order.order_method_id': function(newValue) {
      const order_method =
        this.orderMethods.find(({ id }) => id === newValue) || {};

      this.updateNewOrder({
        order_method_id: newValue,
        order_method,
      });
    },
  },

  methods: {
    ...mapMutations(['updateNewOrder']),
  },
};
</script>
