<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeModal()" />
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">Επεξεργασία Διεύθυνσης</div>
        <button class="delete" @click="closeModal()" />
      </header>
      <form @submit.prevent="submit">
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Όνομα *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newAddress.firstName"
                    type="text"
                    class="input"
                    name="firstName"
                  />
                </div>
                <div v-show="errors.has('firstName')" class="help is-danger">
                  Εισάγετε όνομα
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Επώνυμο *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newAddress.lastName"
                    type="text"
                    class="input"
                    name="lastName"
                  />
                </div>
                <div v-show="errors.has('lastName')" class="help is-danger">
                  Εισάγετε επώνυμο
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Κινητό</label>
                <div class="control">
                  <vue-tel-input
                    v-model="phoneNumbers.mobile"
                    enabled-country-code
                    placeholder="Εισάγετε Κινητό"
                    name="mobile"
                    input-id="edit_customer_address_mobile"
                    @input="onInputMobile"
                  />
                  <p
                    v-show="
                      errors.has('mobile') || errors.has('mobile-invalid')
                    "
                    class="help is-danger"
                  >
                    Εισάγετε έγκυρο κινητό τηλέφωνο
                  </p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Σταθερό</label>
                <div class="control">
                  <vue-tel-input
                    v-model="phoneNumbers.telephone"
                    enabled-country-code
                    input-id="edit_customer_address_telephone"
                    name="telephone"
                    placeholder="Εισάγετε Σταθερό"
                    @input="onInputTelephone"
                  />
                  <p v-show="errors.has('telephone')" class="help is-danger">
                    Εισάγετε έγκυρο σταθερό τηλέφωνο
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Οδός *</label>
                <div class="control">
                  <GmapAutocomplete
                    v-validate="'required'"
                    v-model.lazy="newAddress.street"
                    :options="{
                      types: ['geocode'],
                      componentRestrictions: {
                        country: ['gr', 'cy'],
                      },
                    }"
                    class="input"
                    type="text"
                    name="street"
                    placeholder="Εισάγετε Οδό, Αριθμό & Πόλη"
                    autocomplete="new-password"
                    @focus.native="resetAutocomplete"
                    @keydown.enter.prevent.native
                    @place_changed="updatePlace"
                    @input.native="handleStreetChange"
                  />
                </div>
                <div v-show="errors.has('street')" class="help is-danger">
                  Εισάγετε Οδό, Αριθμό & Πόλη
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Αριθμός *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model="newAddress.streetNumber"
                    class="input"
                    type="text"
                    name="streetNumber"
                    placeholder="Εισάγετε Aριθμό"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                  />
                </div>
                <div v-show="errors.has('streetNumber')" class="help is-danger">
                  Εισάγετε αριθμό
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">ΤΚ *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model="newAddress.postcode"
                    class="input"
                    name="postcode"
                    placeholder="Εισάγετε ΤΚ"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                  />
                </div>
                <div v-show="errors.has('postcode')" class="help is-danger">
                  Εισάγετε ΤΚ
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Πόλη *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model="newAddress.city"
                    class="input"
                    name="city"
                    placeholder="Εισάγετε Πόλη"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                  />
                </div>
                <div v-show="errors.has('city')" class="help is-danger">
                  Εισάγετε πόλη
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Νομός *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model="newAddress.region"
                    class="input"
                    name="region"
                    placeholder="Εισάγετε Νομό"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                  />
                </div>
                <div v-show="errors.has('region')" class="help is-danger">
                  Εισάγετε νομό
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Χώρα</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model.trim="newAddress.country" name="country">
                      <option
                        v-for="(country, index) in countryOptions"
                        :key="index"
                        :value="country.value"
                      >
                        {{ country.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import pick from 'lodash/pick';
import { mapActions } from 'vuex';
import FormSubmit from '@/views/components/FormSubmit';
import LocationsSearch from '@/views/components/LocationsSearch';
import gMapAutocomplete from '@/utils/gMapAutocomplete';
import AddressMixin from '@/mixins/Address';

export default {
  components: {
    FormSubmit,
    LocationsSearch,
  },

  mixins: [AddressMixin],
  props: {
    isOpen: false,
    customer: Object,
    address: Object,
  },

  data() {
    return {
      newAddress: {},
      isSaving: false,
      phoneNumbers: {
        mobile: this.address.mobile,
        telephone: this.address.telephone,
      },
    };
  },

  watch: {
    address(newVal) {
      this.newAddress = pick(newVal, [
        'firstName',
        'lastName',
        'telephone',
        'mobile',
        'postcode',
        'street',
        'streetNumber',
        'city',
        'region',
        'country',
      ]);

      this.phoneNumbers.mobile = this.newAddress.mobile;
      this.phoneNumbers.telephone = this.newAddress.telephone;
    },
  },

  methods: {
    ...mapActions(['updateCustomerAddress']),
    closeModal() {
      this.reset();
      this.$emit('closeModal');
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateCustomerAddress({
          address: {
            ...this.address,
            ...this.newAddress,
            customer_id: this.customer.uuid,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η διεύθυνση αποθηκεύτηκε',
        });

        this.closeModal();
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newAddress = pick(this.address, [
        'firstName',
        'lastName',
        'telephone',
        'mobile',
        'postcode',
        'street',
        'streetNumber',
        'city',
        'region',
        'country',
      ]);

      this.phoneNumbers.mobile = this.address.mobile;
      this.phoneNumbers.telephone = this.address.telephone;

      this.errors.remove('telephone');
      this.errors.remove('mobile-invalid');

      this.$validator.reset();
    },

    handleStreetChange($event) {
      const { name, value } = $event.target;

      this.newAddress = {
        ...this.newAddress,
        street: value,
      };

      this.$nextTick(() => {
        this.$validator.validate(name);
      });
    },

    updatePlace(place) {
      const newPlace = gMapAutocomplete.setPlace(place);

      this.newAddress = {
        ...this.newAddress,
        ...newPlace,
      };
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.newAddress.telephone = number.international;
        this.errors.remove('telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.newAddress.mobile = number.international;
        this.errors.remove('mobile-invalid');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
