const chars = {
  ά: 'α',
  έ: 'ε',
  ή: 'η',
  ί: 'ι',
  ϊ: 'ι',
  ΐ: 'ι',
  ύ: 'υ',
  ό: 'ο',
  ώ: 'ω',
  Ά: 'Α',
  Έ: 'Ε',
  Ή: 'Η',
  Ί: 'Ι',
  Ϊ: 'Ι',
  Ϊ́: 'Ι',
  Ύ: 'Υ',
  Ό: 'Ο',
  Ώ: 'Ω',
};

export default {
  capitalizeAddress(address) {
    let newAddress = {
      ...address,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(address)) {
      if (['street', 'city', 'region'].includes(key)) {
        newAddress = {
          ...newAddress,
          [key]: value
            .toLowerCase()
            .split('')
            .map(char => chars[char] || char)
            .join('')
            .toUpperCase(),
        };
      }
    }

    return newAddress;
  },
};
